.inputClass {
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.5);
  /* opacity: 50%; */
  /* color: white; */
  margin-top: 10px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  margin-bottom: 14px;
  max-width: 527px;
  padding: 0 5%;
  border: 2px solid gray;
  border-radius: 4px;
}

.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 14px;
}

i {
  position: absolute;
  top: 38%;
  right: 5%;
}
i:hover {
  color: #00fcb6;
  cursor: pointer;
}
