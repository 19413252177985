.fw {
  @extend %fw;
}

.f-c {
  @extend %flex !optional;
  @extend %align-c !optional;
}

%fw {
  width: 100%;
}

%text-c {
  text-align: right;
}

.dropdown-true {
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
  border-radius: 4px !important;
}

.dropdown-err {
  border: 1px solid $red !important;
  border-radius: 4px !important;
}

.mt {
  &-10r {
    margin-top: 10rem;
  }

  &-8r {
    margin-top: 8rem;

    @include tablet {
      margin-top: 0rem;
    }

    @include mobile {
      margin-top: 2rem;
      margin-bottom: 3rem;
    }
  }

  &-7r {
    margin-top: 7rem;
  }

  &-6r {
    margin-top: 6rem;
  }

  &-5r {
    margin-top: 5rem;
  }

  &-3r {
    margin-top: 3rem;
  }

  &-2r {
    margin-top: 2rem;
  }

  &-1r {
    margin-top: 1rem;
  }

  &-1-5r {
    margin-top: 1.5rem;
  }

  &-mid {
    margin-top: 0.5rem;
  }

  &-30 {
    margin-top: 30px;
  }

  &-50 {
    margin-top: 50px;
  }
}

.mb {
  &-5r {
    margin-bottom: 5rem;
  }

  &-3r {
    margin-bottom: 3rem;
  }

  &-2r {
    margin-bottom: 2rem;
  }

  &-1r {
    margin-bottom: 1rem;
  }
}

.main {
  height: 100%;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  margin-top: 0;
  padding: 0;
}

.container {
  // max-width: 95vw;
  width: inherit;
  padding-left: 40px;
  padding-right: 40px;

  &__wrapper {
    padding: 0;
  }

  &__title {
    font-size: 1.3rem;

    @include tablet {
      font-size: 1.1rem;
    }
  }

  @include tablet {
    max-width: 100%;
  }

  margin: 0 auto;

  // .logo {
  //   margin-left: -10px;

  //   @include tablet {
  //     margin-left: -7px;
  //   }
  // }

  &.little {
    max-width: 500px;
  }
}

.tracking {
  @extend %flex !optional;
  @extend %flex-column !optional;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  @extend .fw;
  nonce: "asdsa";

  &__carousel {
    .react-swipeable-view-container {
      height: 100% !important;
    }

    padding: 0px 14px;
    // max-width: 380px;
    margin-left: auto;
    margin-right: auto;
  }

  .logo {
    // cursor: pointer;
    width: 11rem;
    margin-top: 15px;

    @include mobile {
      width: 9rem;
      margin-top: 10px;
    }

    svg {
      // cursor: pointer;
      margin: auto 0;
      max-height: 70px;
      padding-bottom: 5px;
      box-sizing: border-box;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    .rrss {
      // @include mobile {
      //   display: none;
      // }

      display: flex;
      list-style: none;
      align-items: center;

      &__figure {
        cursor: pointer;
        margin: 7px;
        // width: 30px;
        // height: 30px;
      }
    }
  }

  &__rrss {
    display: none;
    border-top: 1px solid $graylightest;
    padding-top: 5px;
    padding-bottom: 5px;

    @include mobile {
      display: initial;
    }

    .rrss {
      color: $graylight;
      display: flex;
      list-style: none;
      align-items: center;
      justify-content: center;

      &__figure {
        cursor: pointer;
        margin: 7px;
        width: 36px;
        height: 36px;
      }
    }
  }

  &__ribbon {
    // background-color: $red;
    background-color: #ee3224;
    color: $white;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 17px;
    line-height: 19px;
    text-align: center;
  }

  &__card {
    border: solid 1px $graylightest;
    border-radius: 3px;
    text-align: center;
    padding: 35px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 98%;
    max-width: 380px;
    margin: auto;

    .figure {
      width: 80px;
      height: 80px;
      margin-bottom: 10px;

      svg {
        width: 80px;
        height: 80px;
      }
    }

    .text {
      color: $graylight;

      &.active {
        color: $yellow;
      }
    }
  }

  &__item {
    text-align: center;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .figure {
      width: 80px;
      height: 80px;
      margin-bottom: 10px;

      svg {
        width: 80px;
        height: 80px;
      }
    }

    .text {
      color: $graylight;

      &.active {
        color: $yellow;
      }
    }
  }

  &__body {
    @extend %flex !optional;
    @extend %f-1 !optional;
    @extend %flex-column !optional;
    @extend %justify-c !optional;
    @extend .fw;
    // .m-a {
    //   max-width: 50vw;
    //   @include tablet {
    //     max-width: 90vw;
    //   }
    //   margin-top: 2%;
    // }
    // .check {
    //   width: 80px;
    //   margin: auto;
    // }
    // .code {
    //   font-weight: $font_bold;
    //   font-size: 2rem;
    // }
  }

  &__footer {
    @extend %text-c;
    color: $graylight;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: small;

    * {
      font-size: inherit;
    }
  }
}

.container__footer {
  background-color: #ee3224;
  color: $white;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: bold;
  font-size: 17px;
  line-height: 10px;
  flex: 1 1 50px;
  max-height: 50px;
  font-size: 1.3rem;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0;

  a {
    font-weight: bold;
    color: $white;
  }

  @include tablet {
    font-size: 1.1rem;
  }
}

.files {
  display: flex;
  max-width: 100%;

  @include mobile {
    display: flex;
  }
}

.grupo {
  display: flex;
  flex: 1;
  justify-content: center;
}

.disclaimer {
  text-align: center;

  &__remember {
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
    text-align: justify;
    @include mobile {
      font-size: 12px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @include mobile {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.link {
  color: white;
}

.document--text {
  font-size: 22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);

  @include mobile {
    font-size: 18px;
  }

  span {
    font-weight: bold;
    font-size: 26px;

    @include mobile {
      font-size: 22px;
    }
  }
  &__secundary {
    margin-top: 1rem;
    font-size: 18px;
    text-align: center;
  }
}

.informativo {
  font-size: 20px;
  // text-align:justify !important;
  span {
    font-weight: bold;
    font-size: 20px;

    @include mobile {
      font-size: 18px;
    }
  }
  @include mobile {
    font-size: 18px;
  }
}

// .disclaimer-informativo {
//   text-align: "justify" !important;
//   .grupo {
//     @include mobile {
//       text-align: "justify";
//     }
//     @include tablet  {
//     }
//   }

// }

.document--reintentar {
  font-size: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);

  // span {
  //   font-weight: bold;
  //   font-size: 30px;

  //   @include mobile {
  //     font-size: 18px;
  //   }
  // }
}

.login {
  margin-top: 60px;
  margin-left: 30%;
  margin-right: 30%;
  margin-bottom: 30px;

  @include mobile {
    margin: 40px auto;
    margin-bottom: 0px;
  }
}

.btn--create {
  width: 250px;
  background-color: #ee3224;
  color: white;
  // font-weight: bold;
  height: 48px;
  font-size: 16px;
  border-radius: 5px;
  border: 0.5px solid #c4c4c4;
  cursor: pointer;

  // @include mobile {
  //   width: 100%;
  // }
}

.btn--createOpaco {
  width: 250px;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  // font-weight: bold;
  height: 48px;
  font-size: 16px;
  border-radius: 5px;
  border: 0.5px solid #c4c4c4;
  cursor: pointer;

  @include mobile {
    width: 100%;
  }
}

.btn--create_disabled {
  width: 250px;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  // opacity: 20%;
  // font-weight: bold;
  height: 48px;
  font-size: 16px;
  border-radius: 5px;
  border: 0.5px solid #c4c4c4;
  cursor: pointer;

  @include mobile {
    width: 250px;
  }
}
.btn--clean {
  width: 100px;
  background-color: #ee3224;
  color: white;
  // font-weight: bold;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  border: 0.5px solid #c4c4c4;
  cursor: pointer;

  p {
    text-align: center;
    margin-top: 10px;
  }

  @include mobile {
    width: 100%;
  }
}

.bgred {
  background-color: $red;
}

.btn {
  &__close {
    cursor: pointer;
    display: block;
    margin: 0 0 0 auto;
    width: 32px;
    height: 32px;

    figure {
      width: 32px;
      height: 32px;

      svg,
      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__link {
    text-decoration: underline;
    background: none;
    border: none;
    padding: 0;
    color: rgb(0, 0, 238);
    font-size: inherit;
    cursor: pointer;
  }

  &__back {
    z-index: 1;
    position: absolute;
    top: 1rem;
    left: 1rem;
    cursor: pointer;
    // width: 1.3rem;
    margin-right: 20px;

    &:hover {
      svg {
        filter: drop-shadow(3px 2px 3px rgba(0, 0, 0, 0.3));
      }
    }

    svg {
      filter: drop-shadow(3px 2px 10px rgba(0, 0, 0, 0.3));
    }

    figure {
      height: 36px;
      width: 22px;

      svg {
        height: 36px;
        width: 22px;
      }
    }

    &.little {
      figure {
        height: 25px;
        width: 15px;

        svg {
          height: 25px;
          width: 15px;
        }
      }
    }

    &.red {
      svg * {
        fill: $red;
      }
    }

    &.static {
      position: initial;
    }
  }

  &__modal {
    cursor: pointer;
    display: block;
    height: 14px;
    width: 14px;

    figure {
      @extend %flex !optional;
      height: 14px;
      width: 14px;

      svg,
      img {
        height: 14px;
        width: 14px;
      }
    }
  }

  &__circle {
    border: none;
    padding: 0;
    background: transparent;

    &:focus {
      outline: none;

      svg {
        // box-shadow: 0px 0px 5px $outline;
        filter: drop-shadow(0px 0px 2px $yellow);
        border-radius: 50%;
      }
    }
  }

  &__plain {
    border: none;
    padding: 0;
    background: transparent;

    &:focus {
      outline: none;

      svg {
        filter: drop-shadow(3px 2px 3px rgba(0, 0, 0, 0.8));
      }
    }
  }

  &__core {
    font-size: 1rem;
    border: none;
    border-radius: 3px;
    padding: 10px 40px;
    display: block;
    @extend .f-c;
    color: $white;
    font-weight: $font_normal;
    text-transform: uppercase;
    width: initial;
    outline: none;
    cursor: pointer;

    &[class*="primary"] {
      background-color: $red;
    }

    &[class*="secondary"] {
      background-color: $yellow;
    }

    &[class*="white"] {
      background-color: $white;
      font-weight: $font_bold;
    }

    &[disabled] {
      cursor: initial;
      background-color: $lightgray;
    }

    &__icon {
      @extend .f-c;
      margin-left: 1rem;

      svg {
        width: 1.5rem;
      }
    }

    &--0 {
      padding: 16px 40px;

      svg {
        stroke: $plain__yellow;

        * {
          fill: $plain__yellow;
        }
      }

      color: $plain__yellow;
    }

    &--1 {
      padding: 16px 40px;

      svg {
        stroke: $plain__green;

        * {
          fill: $plain__green;
        }
      }

      color: $plain__green;
    }

    &--2 {
      padding: 16px 40px;

      svg {
        stroke: $plain__purple;

        * {
          fill: $plain__purple;
        }
      }

      color: $plain__purple;
    }
  }
}

.p-ab-0 {
  position: absolute;
  bottom: 0;

  @include desktop {
    position: absolute;
  }

  @include tablet {
    position: relative;
  }

  @include mobile {
    position: relative;
  }
}

.informative {
  display: flex;

  &__col1 {
    display: flex;
    flex-direction: column;
  }

  &__col2 {
    padding-right: 30px;
    padding-left: 30px;
  }

  &__col3 {
    display: flex;
    flex-direction: column;
  }
}

.mobile-direction {
  flex-direction: row;

  @include mobile {
    flex-direction: column;
  }
}

.mat__loading {
  position: fixed;
  z-index: 999;
  height: 3rem;
  width: 3rem;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading {
  opacity: 0;
  position: absolute;
  top: 0;
  overflow: hidden;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($color: $white, $alpha: 1);
  z-index: -100;
  transition: ease 1s;

  div {
    color: rgba($color: $red, $alpha: 1);
  }

  &.active {
    display: initial;
    opacity: 1;
    z-index: 100;
    background-color: rgba($color: $white, $alpha: 0.9);
  }
}

.contacto {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  // overflow-y: 100px;

  &.pre {
    align-self: center;

    @include mobile {
      align-self: initial;
    }
  }

  &__choice {
    width: 500px;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    @include mobile {
      display: flex;
      align-items: center;
      text-align: left;
      width: 100%;
    }
  }

  &__label {
    color: $red;
    font-weight: bold;
    font-size: 18px;
  }
}

.codigo {
  display: flex;
  // justify-content: space-around;
  justify-content: center;

  &__bloque {
    padding-left: 10px;
    padding-right: 10px;

    // width: 70px;
    // margin-left: 20px;
    // margin-right: 20px;
    @include mobile {
      padding-left: 5px;
    }

    &.code {
      border-top: 0px;
      outline: none;
      border-right: 0px;
      border-left: 0px;
      // width: 70px;
      width: 50px;
      border-bottom: 1px solid black;
      text-align: center;
      font-size: 24px;
      height: 40px;

      @include mobile {
        width: 20px;
      }
    }
  }
}

.pregunta {
  display: flex;
  flex-direction: column;
  // overflow-y: 100px;
  height: 520px;
  overflow-y: scroll;

  @include mobile {
    height: 100%;
    overflow-y: hidden;
  }

  &__bloque {
    margin-top: 2rem;
    padding-right: 50px;

    @include mobile {
      padding: 0;
    }

    svg {
      fill: #5e4696 !important;
      width: 40px !important;
      height: 50px !important;
      top: 0 !important;
      right: 0 !important;
      padding-right: 15px;
    }
  }

  &__label {
    color: $red;
    font-weight: bold;
    font-size: 18px;
  }
}

// .answer {
//   display: flex;
//   flex-direction: column;
//   overflow-y: 100px;
// }

.questions {
  margin-top: 60px;
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 100px;

  @include mobile {
    margin: 40px auto;
  }
}

.input {
  &__adornment {
    &__email {
      svg {
        width: 1.5rem;
      }
    }

    &__polygon {
      svg {
        width: 2rem;
      }
    }

    &__phone {
      svg {
        width: 1rem;
        margin-left: 0.3rem;
        margin-right: 0.2rem;
      }
    }

    &__address {
      svg {
        width: 1rem;
        margin-left: 0.3rem;
        margin-right: 0.1rem;
      }
    }

    &__edit {
      cursor: pointer;

      svg {
        width: 1rem;
      }

      &.active {
        svg {
          path {
            fill: $gray;
          }
        }
      }
    }

    &__date {
      cursor: pointer;
      padding-right: 15px;

      svg {
        width: 1.5rem;
      }

      & .active {
        svg {
          path {
            fill: #5e4696;
          }
        }
      }

      & .disabled {
        cursor: default;

        svg {
          path {
            fill: $mediumgray;
          }
        }
      }
    }

    &__search {
      cursor: pointer;

      svg {
        width: 1.5rem !important;
      }

      & .active {
        svg {
          path {
            fill: $gray;
          }
        }
      }
    }
  }
}

.temporal {
  margin-bottom: 0;
}

.past {
  color: rgba(0, 0, 0, 0.4);
}

//modal
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 990;
}

.modal .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 995;
  background: rgba(0, 0, 0, 0.85);
}

.modal .modal_content {
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  overflow: auto;
  background: #fff;
  box-sizing: border-box;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 30px;
  padding-bottom: 30px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  width: auto;
  display: flex;
  flex-direction: column;

  @include mobile {
    padding: 30px;
    // width: 80%;
  }
}

.modal .modal_content > div {
  font-size: 24px;
  margin: 20px 0 20px;
  text-align: center;
  display: inline-block;

  color: rgba(0, 0, 0, 0.65);

  strong {
    font-size: 24px;
  }

  u {
    font-size: 24px;
    color: $red;
    font-weight: bold;
  }

  @include mobile {
    font-size: 18px;
  }
}

.modal .modal_content .buttons_wrapper {
  padding: 20px;
}

.modal .close_modal {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  font-size: 18px;
  opacity: 0.5;
  background: none;
  border: none;
  transition: opacity 0.2s ease;
}

.modal .close_modal:hover {
  opacity: 0.9;
}

.like {
  text-align: center;
  width: 100%;
}

.try--link {
  color: #5e4696;
  text-decoration-line: underline;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;

  @include mobile {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.fd-c {
  flex-direction: column;
}

.label--key {
  // color: $red;
  font-weight: bold;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;

  &__list {
    // margin: 5px 10px 5px 0;
    margin-top: 5px;
    margin-bottom: 20px;
    // margin-right: 10px;
    // width: 210px;
    display: flex;
    width: 50%;
    // flex: 1;
    color: rgba(0, 0, 0, 0.65);
  }
}

.object {
  cursor: pointer;

  svg {
    width: 70px;
    height: 70px;
  }
}

.col-md-6 {
  width: 100%;
}

.keyboard {
  margin-top: 8px;
  margin-bottom: 8px;
  // padding-left: 20px;
}

.keyboard-w {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  width: 250px;
  // justify-content: center;
  // ul {
  //   padding-right: 5px;
  //   padding-bottom: 5px;
  //   img {
  //     // width: 60px;
  //     cursor: pointer;
  //     height: 35px;
  //     border-radius: 3px;
  //     margin: 2px 1px;
  //     box-shadow: 0 2px 2px 0 #d6d6d6;
  //     opacity: .9;
  //   }
  // }
}

.borrar {
  display: inline-block;
  cursor: pointer;
  width: 50%;
  text-align: center;
  background: rgba(196, 196, 196, 0.5);
  height: 60px;
  padding-top: 15px;
}

// .img {
//   display: inline-block;
//   cursor: pointer;
//   img {
//     height: 35px;
//     border-radius: 3px;
//     margin: 2px 1px;
//     box-shadow: 0 2px 2px 0 #d6d6d6;
//     opacity: .9;
//   }
// }

.img-temp {
  height: 35px;
  border-radius: 3px;
  margin: 3px 3px;
  box-shadow: 0 2px 2px 0 #d6d6d6;
  opacity: 0.9;
  cursor: pointer;
}

.clave--box {
  display: flex;

  @include mobile {
    flex-direction: column;
  }
}

.select--login {
  svg {
    fill: #5e4696 !important;
    width: 40px !important;
    height: 50px !important;
    top: 0 !important;
    right: 0 !important;
    padding-right: 15px;

    span {
      font-size: 14px;
    }
  }
}

.disclaimerTemporal {
  color: rgb(238, 50, 36);
  justify-content: justify;

  p {
    text-align: justify;
    width: 375px;
  }

  @include mobile {
    text-align: center;
  }
}

.disclaimerTemp {
  color: rgb(238, 50, 36);
  justify-content: center;
  width: 500px;

  p {
    text-align: justify;
    width: 500px;
    @include mobile {
      width: 250px;

      text-align: center;
    }
  }

  @include mobile {
    text-align: center;
  }
}

.disclaimerClave {
  color: rgb(238, 50, 36);
  justify-content: left;

  // p {
  //   width: 375px;
  // }

  @include mobile {
    text-align: center;
  }
}

.disclaimerClaveBlue {
  color: #5e4696;
  justify-content: left;

  // p {
  //   width: 375px;
  // }

  @include mobile {
    text-align: left;
  }
}

.disclaimerClaveBtn {
  color: rgb(238, 50, 36);
  justify-content: center;

  @include mobile {
    text-align: center;
  }
}

.disclaimerClaveBtnOpaco {
  color: #000000;
  opacity: 20%;
  justify-content: center;

  @include mobile {
    text-align: center;
  }
}

.disclaimerCodigo {
  color: rgb(238, 50, 36);
  justify-content: center;

  p {
    width: 370px;
  }
}

.links--resultado {
  display: flex;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
    margin: 15px auto;
  }
}

.nrodocument {
  font-size: 14px;
}

.calendar {
  svg {
    fill: #5e4696 !important;
    width: 25px !important;
    height: 50px !important;
    top: 0 !important;
    right: 0 !important;
    padding-right: 0px;
  }
}

.banner {
  border: 1px solid #3d92e0;
  border-radius: 4px;
  padding: 20px;
  width: 100%;
  text-align: center;
  color: #3d92e0;
  background-color: #dce9f5;

  &__close {
    float: right;
    cursor: pointer;
  }

  &__info {
    float: left;
  }
}

.errPregunta {
  color: $red;
  justify-content: start;
  text-align: left;

  @include mobile {
    text-align: center;
  }
}

.textProtection {
  position: fixed;
  bottom: 20px;
  text-align: right;
  // padding-bottom: 40px;
  width: 100%;
  padding-right: 90px;

  @include desktop {
    position: absolute;
  }

  @include tablet {
    position: relative;
  }

  @include mobile {
    position: absolute;
    padding-right: 0;
    text-align: center;
  }

  a {
    color: #3f3f3f;
    text-decoration: none;
    font-size: 20px;
    font-weight: 100;

    @include mobile {
      font-size: 15px;
    }
  }
}

.btn--actualizar {
  width: 250px;
  background-color: #5e4696;
  color: white;
  height: 48px;
  font-size: 16px;
  border-radius: 5px;
  border: 0.5px solid #c4c4c4;
  cursor: pointer;

  p {
    text-align: center;
    margin-top: 10px;
  }

  @include mobile {
    width: 100%;
  }
}

.disclaimerActualizacion {
  text-align: center;
}

input:checked {
  background-color: #5e4696 !important;
  accent-color: #5e4696;
  position: relative;
  width: 24px;
  height: 24px;
}
input:checked:after {
  content: "";
  font-size: 1.5em;
  background-color: #5e4696 !important;
  accent-color: #5e4696;
  position: absolute;
  right: 1px;
  top: -5px;
}
input:checked::before {
  content: "";
  font-size: 1.5em;
  color: #fff;
  position: absolute;
  right: 1px;
  top: -5px;
}
// .checkbox-wrapper input[type="checkbox"]:disabled {
//   border-color: #c0c0c0;
//   background-color: #c0c0c0;
//   width: 25px !important;
//   height: 25px !important;
//   margin-right: 35px;
//   outline: 2px auto #5e4696
// }

// .checkbox-wrapper input[type="checkbox"]:disabled + span {
//   color: #c0c0c0;
//   width: 25px !important;
//   height: 25px !important;
//   margin-right: 35px;
// }

// .checkbox-wrapper input[type="checkbox"]:focus {
//   box-shadow: 0 0 10px#5e4696;
//   width: 25px !important;
//   height: 25px !important;
//   margin-right: 35px;
// }
.chErr {
  outline: 2px auto $red !important;
  accent-color: $red;
  // svg {
  //   fill: $red !important;
  //   width: 35px !important;
  //   height: 35px !important;
  //   top: 0 !important;
  //   right: 0 !important;
  //   padding-right: 15px;
  // }
}

.chTrue {
  outline: 2px auto #5e4696 !important;

  // svg {
  //   fill: #5e4696 !important;
  //   width: 35px !important;
  //   height: 35px !important;
  //   top: 0 !important;
  //   right: 0 !important;
  //   padding-right: 15px;
  // }
}

.checkTimes {
  width: 25px !important;
  height: 25px !important;
  margin-right: 35px;
  outline: 2px auto #5e4696;
  outline-offset: -1px;

  // svg {
  //   fill: #5e4696 !important;
  //   width: 35px !important;
  //   height: 35px !important;
  //   top: 0 !important;
  //   right: 0 !important;
  //   padding-right: 15px;
  // }
}

.footterTemporal {
  position: absolute;
  bottom: 28px;
  text-align: justify;
  margin-left: 91px;
  margin-right: 91px;

  @include mobile {
    position: sticky;

    margin-left: 20px;
    margin-right: 20px;
    top: 480px;
  }
}
.grecaptcha-badge {
  bottom: 52px !important;
  @include tablet {
    bottom: 111px !important;
  }
}
