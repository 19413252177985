%flex {
    display: flex;
  }
  
  %flex-column {
    flex-direction: column;
  }
  
  %justify-right {
    justify-content: flex-end !important;
  }
  
  %justify-left {
    justify-content: left !important;
  }
  
  %align-c {
    align-items: center;
  }
  
  %align-e {
    align-items: flex-end;
  }
  
  %justify-c {
    justify-content: center;
  }
  
  %justify-b {
    justify-content: space-between;
  }
  
  %wrap {
    flex-wrap: wrap;
  }
  
  %f-1 {
    flex: 1;
  }