.modalFirstMessage {
  display: flex;
  flex-direction: column;
  width: 400px;
}

@media (max-width: 768px) {
  .modalFirstMessage {
    width: 100%;
  }
}
