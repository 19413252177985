$black: #000000;
$white: #ffffff;
$primary: #7e0063;
// $gray: #737373;
$red: #EE3224;
$yellow: #ffb80f;
$lightyellow: lighten($yellow, 20%);
$outline: rgba(0, 3, 238, 0.918);
$gray: #717171;
$darkgray: darken($gray, 10%);
$lightgray: lighten($gray, 50%);
$mediumgray: lighten($gray, 30%);
$materialgray: #bebebe;
$theme-color: #282c34 !default;

$seablue: #0f9bf6;
$platzibg: #234050;
$platzigreen: #97c93e;

$error: #db2828;
$lightred: lighten($red, 50%);
$success: #21ba45;
$alert: #fbbd07;
$info: #6435c9;

$link: #61dafb;

$graylightest: #e8e8e8;
$graylight: #a8a8a8;

$blue__dni: #62bae2;

$category__green: linear-gradient(24.95deg, #7cbf4a 1.6%, #12895f 236.3%);
$category__yellow: linear-gradient(25.2deg,
  rgba(253, 187, 48, 0.73) 0%,
  #fdbb30 101.01%);
$category__purple: linear-gradient(25.99deg, #83156e 0%, #df1376 101.19%);

$plain__green: #7cbf4a;
$plain__yellow: rgba(253, 187, 48, 0.73);
$plain__purple: lighten(#83156e, 5%);